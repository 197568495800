import { PrimaryButton, SecondaryButton } from 'components/Button'
import { Row } from 'components/Flex'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const StyledHeader = styled(Row)`
  top: 0;
  z-index: 1000;
  padding: 20px 24px;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  justify-content: flex-end;
`

export default function Header() {
  return (
    <StyledHeader>
      <Row gap={12}>
        <Link to="https://www.geckoterminal.com/starknet-alpha/pools/0x398082cc60d530ed0d2b7e9c09239918f4561133b895c247cc6c1229ab904f8">
          <SecondaryButton>Chart</SecondaryButton>
        </Link>

        <Link
          to="https://app.avnu.fi/en?tokenFrom=0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7&tokenTo=0x0412396984a874866f68334f0997b0b897e3a0b29a4f8d64861126b9bd171a98"
          target="_blank"
        >
          <PrimaryButton>Buy</PrimaryButton>
        </Link>
      </Row>
    </StyledHeader>
  )
}
