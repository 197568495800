import background from 'assets/background.jpg'
import logo from 'assets/logo.png'
import LogoText from 'assets/logo-text.png'
import { PrimaryButton } from 'components/Button'
import { Column } from 'components/Flex'
import { transparentize } from 'polished'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

const Background = styled.span`
  z-index: -99;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  max-height: 1000px;
  background-image: url('${background}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(
      to bottom,
      ${({ theme }) => transparentize(0.7, theme.bg1)},
      ${({ theme }) => theme.bg1}
    );
  }
`

const Section = styled.section`
  width: 100%;
  padding: 64px 12px 96px;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: -24px auto 0;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding-top: 32px;
  }

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    padding-top: 0;
  }
`

// First article

const FirstArticle = styled(Column)`
  gap: 50px;
  width: 100%;
`

const Title = styled.img`
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
`

const Motto = styled(ThemedText.Hero)`
  font-size: 48px !important;
  text-shadow: 0 0 10px ${({ theme }) => theme.black};

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    font-size: 64px !important;
  }
`

const SocialButton = styled(PrimaryButton)`
  width: 156px;
`

const Logo = styled.img`
  width: 200px;
  height: 200px;
`

const SocialContainer = styled(Column)`
  gap: 28px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    flex-direction: row;
  }
`

const ContractAddress = styled(ThemedText.BodyPrimary)`
  word-break: break-all;
  text-align: center;
`

// Second article

const SecondArticle = styled(Column)`
  margin-top: 80px;
  gap: 4px;

  @media only screen and (min-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    margin-top: 100px;
  }
`

export default function HomePage() {
  return (
    <>
      <Background />

      <Section>
        <FirstArticle>
          <Title src={LogoText} />

          <Column gap={28}>
            <Motto textAlign="center">
              IT&apos;S OFTEN IMPORTANT
              <br />
              TO FOLLOW YOUR OWN IDEAS
            </Motto>

            <ThemedText.HeadlineSmall textAlign="center" fontWeight={300}>
              even when you&apos;re useless you can still be independent
            </ThemedText.HeadlineSmall>

            <SocialContainer>
              <Link to="https://twitter.com/leastaligned" target="_blank">
                <SocialButton>Twitter</SocialButton>
              </Link>

              <Logo src={logo} />

              <Link to="https://t.me/leastaligned" target="_blank">
                <SocialButton>Telegram</SocialButton>
              </Link>
            </SocialContainer>

            <ContractAddress>0x0412396984a874866f68334f0997b0b897e3a0b29a4f8d64861126b9bd171a98</ContractAddress>
          </Column>
        </FirstArticle>

        <SecondArticle>
          <ThemedText.HeadlineLarge fontWeight={900}>DISCLAIMER</ThemedText.HeadlineLarge>
          <ThemedText.HeadlineMedium textAlign="center" fontWeight={300}>
            $LEA is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or
            roadmap. The coin is completely useless and for entertainment purposes only.
          </ThemedText.HeadlineMedium>
        </SecondArticle>
      </Section>
    </>
  )
}
