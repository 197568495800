// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx

const colors = {
  white: '#FFFFFF',
  black: '#000000',

  neutral1_dark: '#f7f7f7',
  neutral2_dark: '#898CA9',
}

const commonTheme = {
  white: colors.white,
  black: colors.black,

  gradient1: 'linear-gradient(225deg, #feac5e 14.89%, #c779d0, #4bc0c8 85.85%)',
  gradient2: 'linear-gradient(52deg, #c17ed0 -11.18%, #cda3d2 58.92%, #a9c4f3 98.44%)',

  accent1: '#c779d0',
}

export const darkTheme = {
  ...commonTheme,

  bg1: '#0B0B0F',
  bg2: '#1A1B23',

  neutral1: colors.neutral1_dark,
  neutral2: colors.neutral2_dark,
}
